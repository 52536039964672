.post {
    width: 100%;
    margin-top: 15px;
    border-radius: 25px;
    background-color: whitesmoke;
    box-shadow: 5px 5px 7px -7px rgba(0, 0, 0, 0.5);
}

.postHeader {
    display: flex;
    position: relative;
    align-items: center;
    padding: 15px;
}

.postHeaderText h3 {
    font-size: medium;
}

.postHeaderText p {
    font-size: small;
    color: gray;
}

.postContent {
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 15px;
}