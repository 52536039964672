.header {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    background-color: whitesmoke;
    z-index: 100;
    top: 0px;
    box-shadow: 0px 5px 9px -9px rgba(0, 0, 0, 0.5);
}

.headerLeft {
    display: flex;
    justify-content: space-evenly;
}

.headerLeft p {
    color: black;
    font-weight: bold;
    padding-right: 10px;
}

.headerSearch {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    margin-left: 10px;
    border-radius: 100px;
}

.headerSearch input {
    border: none;
    background-color: white;
    outline-width: 0;
}

.headerCenter {
    display: flex;
    flex: 1;
    justify-content: center;
}

.headerRight {
    display: flex;
    align-items: center;
}

.headerBtn {
    display: flex;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
}

.headerBtn:hover {
    background-color: white;
    border-radius: 10px;
    border-bottom: none;
}

.headerBtn .MuiSvgIcon-root {
    color: gray;
}

.headerBtnActive {
    border-bottom: 4px solid #ec6a19;
    border-radius: 10px;
}

.headerBtnActive:hover {
    border-bottom: 4px solid #ec6a19;
    background-color: white;
    border-radius: 10px;
}

.headerBtnActive .MuiSvgIcon-root {
    color: #ec6a19;
}