.postSender {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    background-color: whitesmoke;
    border-radius: 25px;
    box-shadow: 5px 5px 7px -7px rgba(0, 0, 0, 0.5);
    width: 100%;
}

.postSender form {
    flex: 1;
    display: flex;
    padding: 15px;
}

.postSender form input {
    outline-width: 0;
    border: none;
    padding: 5px 20px;
    margin: 0 10px;
    border-radius: 100px;
}